import React from "react";
import { Link, navigate } from "gatsby";
import { Icon, Sidebar, Menu } from "semantic-ui-react";
import _ from "lodash";
import logo from "../../images/logo_transparent_small.png";
import css from "./layout.css";
import GitInfo from "react-git-info/macro";
import colours from "../../data/colours.json";

const SideBar = ({ handleItemClick }) => {
  //state = { activeItem: 'home' }

  // handleItemClick = (e) => {
  //   this.setState({ activeItem: e.name })
  // };

  function returnTag() {
    if (GitInfo().branch === "master") {
      return <div style={{ color: "white", padding: 20 }}>Live Version</div>;
    }
    if (
      GitInfo().branch === "master-pre-deploy" ||
      GitInfo().branch === "prod-pre-deploy-v2"
    ) {
      return <div style={{ color: "white", padding: 20 }}>Live Version</div>;
    }
    return <div style={{ color: "white", padding: 20 }}>Test Version</div>;
  }

  var sidebar_colour = "#2D5855";
  var border_colour = "#284f4c";
  switch (GitInfo().branch) {
    case "test":
      var sidebar_colour = _.find(colours, { text: "Grey Dark" }).value;
      var border_colour = "#4f4f4f";
      break;
    case "test-pre-deploy":
      var sidebar_colour = _.find(colours, { text: "Grey Dark" }).value;
      var border_colour = "#4f4f4f";
      break;
  }

  return (
    <div>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible
        width="thin"
        style={{
          backgroundColor: sidebar_colour,
          width: 200,
        }}
      >
        {/* <div>{branch_name}</div> */}
        <Link
          style={{ borderBottom: border_colour }}
          // className="sidebar_item"
          name="content"
          //active={activeItem === 'tiles'}
          onClick={handleItemClick}
          to="/"
        >
          <div
            style={{
              width: "100%",
              borderColor: border_colour,
            }}
          >
            <img
              src={logo}
              className="sidebar_img"
              alt="MySunrise Logo"
              style={{
                width: 200,
                padding: 20,
                paddingTop: 40,
                borderBottom: border_colour,
              }}
            />
          </div>
        </Link>
        <Link
          style={{ borderColor: border_colour }}
          className="sidebar_item"
          name="content"
          //active={activeItem === 'tiles'}
          onClick={handleItemClick}
          to="/content"
        >
          <Icon name="list ul" size="large" />
          <div className="sidebar_text">Content</div>
        </Link>
        <Link
          style={{ borderColor: border_colour }}
          className="sidebar_item"
          name="structure"
          //active={activeItem === 'archive'}
          onClick={handleItemClick}
          to="/pathways"
        >
          <Icon name="code branch" size="large" />
          <div className="sidebar_text">Pathways</div>
        </Link>
        <Link
          style={{ borderColor: border_colour }}
          className="sidebar_item"
          name="structure"
          //active={activeItem === 'archive'}
          onClick={handleItemClick}
          to="/wellness"
        >
          <Icon name="heartbeat" size="large" />
          <div className="sidebar_text">Wellness</div>
        </Link>

        <Link
          style={{ borderColor: border_colour }}
          className="sidebar_item"
          name="surveys"
          //active={activeItem === 'trusts'}
          onClick={handleItemClick}
          to="/surveys"
        >
          <Icon name="talk" size="large" />
          <div className="sidebar_text">Surveys</div>
        </Link>
        <Link
          style={{ borderColor: border_colour }}
          className="sidebar_item"
          name="monitoring"
          //active={activeItem === 'archive'}
          onClick={handleItemClick}
          to="/monitoring"
        >
          <Icon name="user md" size="large" />
          <div className="sidebar_text">Monitoring</div>
        </Link>
        <Link
          style={{ borderColor: border_colour }}
          className="sidebar_item"
          name="Users"
          //active={activeItem === 'trusts'}
          onClick={handleItemClick}
          to="/admin"
        >
          <Icon name="setting" size="large" />
          <div className="sidebar_text">Admin</div>
        </Link>
        <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
          {" "}
          {returnTag()}
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
