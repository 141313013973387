import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { navigate } from "gatsby";

import Header from "./Header";
import SideBar from "./SideBar";

const Index = ({ children }) => {
  // const user = useSelector((state) => state.auth);
  // if (user.isAuthenticated === false) {
  //   navigate("/");
  // }

  return (
    <div>
      <Header />
      <SideBar />
      <div style={{ marginLeft: 200, paddingLeft: 30, paddingRight: 30 }}>
        {children}
      </div>
    </div>
  );
};

export default Index;
