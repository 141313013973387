import React from "react";
import { Input, Menu, Dropdown, Button } from "semantic-ui-react";
import { Link, navigate } from "gatsby";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import _ from "lodash";
import { authStatus } from "../../actions";

class Header extends React.Component {
  state = { activeItem: "home" };

  handleLogout = async (event) => {
    console.log("trying to logout");
    const response = await Auth.signOut();
    console.log(response);
    this.props.authStatus({ isAuthenticated: false });
    //navigate("/")
  };

  renderTrustName(trusts, user_id) {
    const trust = _.find(trusts, { trust_id: user_id });
    // const name = trusts.map((trust) => {
    //   if (trust.trust_id === user_id) {
    //     return trust.name
    //   }
    //   return null
    // })
    if (trust !== undefined) {
      return (
        <div>
          <strong>Trust Selected: </strong> {trust.name}{" "}
          <strong>(V {trust.version}) </strong>
        </div>
      );
    }
    return;
  }

  render() {
    const name = this.props.user.given_name + " " + this.props.user.family_name;
    return (
      <Menu style={{ marginLeft: 150, marginTop: 0 }}>
        <Menu.Menu position="right">
          {/* <Menu.Item>
            <Input icon='search' placeholder='Search...' />
          </Menu.Item> */}

          <Menu.Item>
            {this.renderTrustName(
              this.props.trusts,
              this.props.user["custom:trust"]
            )}
          </Menu.Item>
          <Dropdown item simple text={this.props.user.email}>
            <Dropdown.Menu>
              <Dropdown.Item to="/user">
                <Link style={{ color: "black" }} to="/settings">
                  Settings
                </Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/add_data")}>
                Add Data
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
        <Menu.Item>
          <Button primary onClick={() => navigate("/publish")}>
            Publish
          </Button>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, user: state.user, trusts: state.trusts };
};

export default connect(mapStateToProps, { authStatus })(Header);
